<template>
  <admin>
    <metatag title="DCSI Dashboard"></metatag>
    <page-header>
      <template #title>DCSI Dashboard</template>
      <template #action>
        <v-menu class="justify-end d-flex" transition="slide-y-transition" offset-y nudge-bottom>
          <template v-slot:activator="{ on: menu }">
            <div v-on="{ ...menu }" role="button">
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                large
                color="primary"
                exact
                class="mx-5 mx-md-2 mt-2 mt-md-0 white--text"
                :loading="downloadLoading"
                :disabled="downloadLoading"
              >
                <v-icon small left>mdi-cloud-download</v-icon>
                <span v-text="'Download'"></span>
              </v-btn>
            </div>
          </template>

          <v-list max-width="250">
            <v-list-item exact @click="exportAsPDF">
              <v-list-item-content class="text-right">
                <v-list-item-title v-text="trans('As PDF')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item exact @click="exportAsExcel">
              <v-list-item-content class="text-right">
                <v-list-item-title v-text="trans('As Excel')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </page-header>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <survey-filter
          ref="toolbar"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :filterSearch="false"
          :bottomdivider="false"
          :start-end-date="filters.date"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <can :code="['dealer_survey', 'dealer']">
        <survey-filter
          ref="toolbar"
          :showAdvanceFilterBtn="false"
          :filterDateRange="true"
          :filterRegions="false"
          :filterGroups="false"
          :filterZones="false"
          :filterDealer="false"
          :filterSearch="false"
          :bottomdivider="false"
          :start-end-date="filters.date"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>
    </v-card>

    <v-container class="px-0 pt-8">
      <v-row>
        <v-col
          v-for="(scoreOverviewItem, key) in scoreOverviewItems"
          :key="key"
          class="pt-0 mb-2 col col-md-4 px-2"
        >
          <v-card class="mx-auto" style="height: 100%">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ scoreOverviewItem.title }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="scoreOverviewItem.subtitle">
                  <small>{{ scoreOverviewItem.subtitle }}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text class="pt-0">
              <v-row align="center">
                <v-col class="text-h4 font-weight-bold">
                  {{ scoreOverviewItem.score
                  }}{{ scoreOverviewItem.title == "Respondent Ratio" ? "%" : "" }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0 pb-3">
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
              Score Trend
              <v-spacer></v-spacer>
              <can :code="['admin']">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon v-bind="attrs" v-on="on" class="white--text">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :to="{ name: 'survey.dcsi.upload' }">
                      <v-list-item-title>
                        <small>
                          <v-icon small left>mdi-pencil</v-icon>
                          <span v-text="'Edit Target Score'"></span>
                        </small>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </can>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="reappendChart" class="score-trend-chart-container">
                <canvas class="score-trend-chart"></canvas>
              </div>
              <div v-else style="height: 200px"></div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
              >CDC Trend</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <div>
                <line-chart
                  :data="cdcTrend.data"
                  :colors="['#4CAEE3']"
                  :curve="false"
                  :legend="false"
                  :discrete="true"
                  :library="cdcTrend.options"
                ></line-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0 py-3">
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
              Respondent Trend
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="reappendChart" class="respondent-trend-chart-container">
                <canvas class="respondent-trend-chart"></canvas>
              </div>
              <div v-else style="height: 200px"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
              >Regional Score</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <div>
                <philippine-map-chart
                  map-color="blue"
                  :items="regionalScoreItems"
                  show-legends
                ></philippine-map-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0 py-3">
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
              Factor Score
              <v-spacer></v-spacer>
              <can :code="['admin']">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text icon v-bind="attrs" v-on="on" class="white--text">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :to="{ name: 'survey.dcsi.upload.factor' }">
                      <v-list-item-title>
                        <small>
                          <v-icon small left>mdi-pencil</v-icon>
                          <span v-text="'Edit Target Score'"></span>
                        </small>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </can>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div v-if="reappendChart" class="factor-score-chart-container">
                <canvas class="factor-score-chart"></canvas>
              </div>
              <div v-else style="height: 200px"></div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
              >Attributes</v-card-title
            >
            <v-divider></v-divider>
            <v-card-text>
              <div>
                <bar-chart
                  :data="topBottomAttributes.data"
                  :colors="['#4CAEE3', '#D8392B']"
                  :stacked="true"
                  :library="topBottomAttributes.options"
                  :max="topBottomAttributes.maxScore"
                ></bar-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <html-dashboard-to-pdf
      ref="htmlToPdf"
      :scoreOverviewItems="scoreOverviewItems"
      :cdcTrend="cdcTrend"
      :regionalScoreItems="regionalScoreItems"
      :topBottomAttributes="topBottomAttributes"
      :file-name="'dcsi-dashboard'"
    >
    </html-dashboard-to-pdf>
  </admin>
</template>

<script>
import Vue from "vue";
import HtmlDashboardToPdf from "../components/components/HtmlDashboardToPdf.vue";
import * as helpers from "@/core/helpers";
import PhilippineMapChart from "../components/components/PhilippineMapChart.vue";
import CircleValue from "../components/partials/CircleValue.vue";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import apiSurveyDcsiReportService from "@/services/api/modules/surveyDcsiReportService.js";
import dummyDataMixins from "@/modules/Survey/modules/Reports/config/dummyDataMixins";
import {
  topBottomAttributesOptions,
  cdcTrendOptions,
  ADDED_TO_CHART_SMALL_SCORING,
  ADDED_TO_CHART_LARGE_SCORING,
} from "../config/chartConfig";

import { mapActions, mapGetters } from "vuex";

import { setupFactorScoreChart } from "../config/factorScoreChart";
import { setupScoreTrendChart } from "../config/scoreTrendChart";
import { setupRespondentTrendChart } from "../config/respondentTrendChart";

Chart.plugins.register(ChartDataLabels);
Vue.use(Chartkick.use(Chart));

export default {
  mixins: [dummyDataMixins],
  components: {
    PhilippineMapChart,
    CircleValue,
    HtmlDashboardToPdf,
  },
  data: () => ({
    downloadLoading: false,
    regionalScoreItems: [],
    scoreOverviewItems: [],
    scoreTrend: {},
    cdcTrend: { ...cdcTrendOptions },
    respondentTrend: {},
    topBottomAttributes: { ...topBottomAttributesOptions },
    factorScore: {},
    filters: {
      dealers: [],
      groups: [],
      zones: [],
      regions: [],
      date: [],
    },
    pastFilterDealers: [],
    reappendChart: true, // use to reappend canvass element where chart is rendered
  }),
  watch: {
    filters: {
      handler(newValue) {
        // this.reappendChart = false;
        // this.getScoreOverview(newValue);
        // this.getRegionalScore(newValue);
        // this.getFactorScore(newValue);
        // this.getScoreTrend(newValue);
        // this.getCdcTrend(newValue);
        // this.getTopBottomAttributes(newValue);
        // this.getRespondentTrend(newValue);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getDealerZones: "dealerZone/getDealerZones",
      getDealers: "dealer/getDealers",
      downloadDcsiRaw: "survey/downloadDcsiRaw",
    }),
    setupFactorScoreChart() {
      setTimeout(() => {
        this.reappendChart = true;

        /**
         * Add delay so that chart will be rendered properly
         */
        setTimeout(() => {
          const ctx = document.getElementsByClassName("factor-score-chart");

          for (let i = 0; i < ctx.length; i++) {
            new Chart(ctx[i], this.factorScore);
          }
        }, 200);
      });
    },
    setupScoreTrendChart() {
      setTimeout(() => {
        this.reappendChart = true;

        /**
         * Add delay so that chart will be rendered properly
         */
        setTimeout(() => {
          const ctx = document.getElementsByClassName("score-trend-chart");

          for (let i = 0; i < ctx.length; i++) {
            new Chart(ctx[i], this.scoreTrend);
          }
        }, 200);
      });
    },
    setupRespondentTrendChart() {
      setTimeout(() => {
        this.reappendChart = true;

        /**
         * Add delay so that chart will be rendered properly
         */
        setTimeout(() => {
          const ctx = document.getElementsByClassName("respondent-trend-chart");

          for (let i = 0; i < ctx.length; i++) {
            new Chart(ctx[i], this.respondentTrend);
          }
        }, 200);
      });
    },
    async setDateRange(e) {
      console.log("@setDateRange", { e });
      if (e) {
        this.filters.date = e;

        await this.getDealerZones(this.filters.regions);

        await this.getDealers({
          region_types: this.filters.regions ? this.filters.regions : [],
          zones: this.filters.zones ? this.filters.zones : [],
          groups: this.filters.groups ? this.filters.groups : [],
        });
      }

      this.checkDealerFilterIfPresent();
      this.loadDashbaordData(this.filters);
    },
    async setRegions(e) {
      console.log("@setRegions", { e });
      if (e) {
        this.filters.regions = [e];
      } else {
        this.filters.regions = [];
      }

      await this.getDealers({
        region_types: this.filters.regions ? this.filters.regions : [],
        zones: this.filters.zones ? this.filters.zones : [],
        groups: this.filters.groups ? this.filters.groups : [],
      });

      this.checkDealerFilterIfPresent();
      this.loadDashbaordData(this.filters);
    },
    async setZones(e) {
      console.log("@setZones", { e });
      if (e) {
        this.filters.zones = [e.value];
      } else {
        this.filters.zones = [];
      }

      await this.getDealers({
        region_types: this.filters.regions ? this.filters.regions : [],
        zones: this.filters.zones ? this.filters.zones : [],
        groups: this.filters.groups ? this.filters.groups : [],
      });

      this.checkDealerFilterIfPresent();
      this.loadDashbaordData(this.filters);
    },
    async setGroups(e) {
      console.log("@setGroups", { e });
      if (e) {
        this.filters.groups = [e.value];
      } else {
        this.filters.groups = [];
      }

      await this.getDealers({
        region_types: this.filters.regions ? this.filters.regions : [],
        zones: this.filters.zones ? this.filters.zones : [],
        groups: this.filters.groups ? this.filters.groups : [],
      });

      this.checkDealerFilterIfPresent();
      this.loadDashbaordData(this.filters);
    },
    setDealer(e) {
      console.log("@setDealer", { e });
      if (e) {
        this.filters.dealers = [e];
      } else {
        this.filters.dealers = [];
      }

      this.loadDashbaordData(this.filters);
    },
    checkDealerFilterIfPresent() {
      if (this.filters.dealers.length > 0) {
        const areDealersPresent = this.filters.dealers.every((value) =>
          this.dealers.some((obj) => obj.value === value)
        );

        if (!areDealersPresent) {
          this.pastFilterDealers = this.filters.dealers;
          this.filters.dealers = [];
        }
      } else {
        if (this.pastFilterDealers.length > 0) {
          const arePastDealersPresent = this.pastFilterDealers.every((value) =>
            this.dealers.some((obj) => obj.value === value)
          );

          if (arePastDealersPresent) {
            this.filters.dealers = this.pastFilterDealers;
          }
        }
      }
    },
    download() {
      this.downloadLoading = true;
      setTimeout(() => {
        console.log("download");
        this.downloadLoading = false;
      }, 1000);
    },
    async getScoreOverview(filters = {}) {
      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
      };

      const csiScore = {
        title: "CSI Score",
        subtitle: "Total CSI Score",
        score: 0,
      };

      const respondents = {
        title: "Respondents",
        subtitle: "Total number of respondents",
        score: 0,
      };

      const overallStatisfactionScore = {
        title: "Overall Satisfaction Score",
        subtitle: "Total Overall Satisfaction Score",
        score: 0,
      };

      const npsScore = {
        title: "NPS Score",
        subtitle: "Total NPS Score",
        score: 0,
      };

      const cdcCount = {
        title: "CDC Count",
        subtitle: "Total number of CDC",
        score: 0,
      };

      const respondentRatio = {
        title: "Respondent Ratio",
        subtitle: "Total Respondent Ratio",
        score: 0,
      };

      await apiSurveyDcsiReportService
        .getScoreOverview(params)
        .then((response) => {
          let totals = response.data.data;

          csiScore.score = totals.csi_score || 0;
          respondents.score = totals.respondents_count || 0;
          overallStatisfactionScore.score = totals.overall_satisfaction_score || 0;
          npsScore.score = totals.nps_score || 0;
          cdcCount.score = totals.cdc_count || 0;
          respondentRatio.score = totals.respondent_ratio || 0;
        })
        .finally(() => {
          this.scoreOverviewItems = [
            csiScore,
            respondents,
            overallStatisfactionScore,
            npsScore,
            cdcCount,
            respondentRatio,
          ];
        });
    },
    async getRegionalScore(filters = {}) {
      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
      };

      await apiSurveyDcsiReportService.getRegionalScore(params).then((response) => {
        let data = response.data.data;

        const metroManila = {
          value: 0,
          name: "Metro Manila",
          coordinateX: 105,
          coordinateY: 100,
          bgColor: "#f44336 ",
        };

        const provincial = {
          value: 0,
          name: "Provincial",
          coordinateX: 180,
          coordinateY: 220,
          bgColor: "#009688",
          textResultClass: "black--text font-weight-bold",
        };

        data.forEach((item) => {
          if (item.region_type === "Metro Manila") {
            metroManila.value = item.overall_satisfaction_score;
          }

          if (item.region_type === "Provincial") {
            provincial.value = item.overall_satisfaction_score;
          }
        });

        this.regionalScoreItems = [metroManila, provincial];
      });
    },
    async getFactorScore(filters = {}) {
      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
        year: filters.date[0] ? filters.date[0].substr(0, 4) : null,
      };

      await apiSurveyDcsiReportService.getFactorScore(params).then((response) => {
        let data = response.data.data;

        this.factorScore = setupFactorScoreChart({
          data,
          plugins: [ChartDataLabels],
        });

        this.setupFactorScoreChart();
      });
    },
    async getScoreTrend(filters = {}) {
      let year = filters.date[0] ? filters.date[0].substr(0, 4) : null;

      if (year != null) {
        let filter_date = new Date(filters.date[0]);

        var month_number = filter_date.toLocaleString("default", { month: "2-digit" });

        let prevs = ["01", "02", "03"];

        if (prevs.includes(month_number)) {
          year = parseInt(year) - 1;
        }
      }
      
      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
        year: year,
      };

      await apiSurveyDcsiReportService.getScoreTrend(params).then((response) => {
        let periods = response.data.data.periods;
        let targetScores = response.data.data.target_score;

        this.scoreTrend = setupScoreTrendChart({
          data: periods,
          targetScores,
          plugins: [ChartDataLabels],
        });

        this.setupScoreTrendChart();
      });
    },
    async getCdcTrend(filters = {}) {
      let year = filters.date[0] ? filters.date[0].substr(0, 4) : null;

      if (year != null) {
        let filter_date = new Date(filters.date[0]);

        var month_number = filter_date.toLocaleString("default", { month: "2-digit" });

        let prevs = ["01", "02", "03"];

        if (prevs.includes(month_number)) {
          year = parseInt(year) - 1;
        }
      }

      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        year: year,
      };

      await apiSurveyDcsiReportService.getCdcTrend(params).then((response) => {
        let periods = response.data.data;

        const data = {};

        let tempHighestScore = 0;

        periods.forEach((item) => {
          tempHighestScore = tempHighestScore >= item.cdc_count ? tempHighestScore : item.cdc_count;
          data[item.month] = item.cdc_count;
        });

        tempHighestScore = Number(tempHighestScore);
        this.cdcTrend.maxScore = Math.ceil(
          tempHighestScore + tempHighestScore * ADDED_TO_CHART_SMALL_SCORING
        );

        this.cdcTrend.data = data;
      });
    },
    async getTopBottomAttributes(filters = {}) {
      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
      };

      await apiSurveyDcsiReportService.getTopBottomAttributes(params).then((response) => {
        const questions = response.data.data;
        let top = {
          name: "Top",
          data: {},
        };
        let bottom = {
          name: "Bottom",
          data: {},
        };

        let yLabels = [];

        questions.top.forEach((item) => {
          top.data[item.question_title] = item.score;

          yLabels.push(item.question_title_truncate);
        });

        questions.bottom.forEach((item) => {
          if (!top.data[item.question_title]) {
            bottom.data[item.question_title] = item.score;

            yLabels.push(item.question_title_truncate);
          }
        });

        let tempHighestScore = 0; //used for the max value of the chart

        questions.top.forEach((item) => {
          tempHighestScore = tempHighestScore >= item.score ? tempHighestScore : item.score;
          top.data[item.question_title] = item.score;
        });

        questions.bottom.forEach((item) => {
          if (!top.data[item.question_title]) {
            tempHighestScore = tempHighestScore >= item.score ? tempHighestScore : item.score;
            bottom.data[item.question_title] = item.score;
          }
        });

        tempHighestScore = Number(tempHighestScore);
        this.topBottomAttributes.maxScore = Math.ceil(
          tempHighestScore + tempHighestScore * ADDED_TO_CHART_SMALL_SCORING
        );

        this.topBottomAttributes.options.scales = {
          yAxes: [
            {
              type: "category",
              labels: yLabels,
            },
          ],
        };

        this.topBottomAttributes.data = [top, bottom];
      });
    },
    async getRespondentTrend(filters = {}) {
      let year = filters.date[0] ? filters.date[0].substr(0, 4) : null;

      if (year != null) {
        let filter_date = new Date(filters.date[0]);

        var month_number = filter_date.toLocaleString("default", { month: "2-digit" });

        let prevs = ["01", "02", "03"];

        if (prevs.includes(month_number)) {
          year = parseInt(year) - 1;
        }
      }

      const params = {
        dealers: filters.dealers,
        groups: filters.groups,
        zones: filters.zones,
        regions: filters.regions,
        date: filters.date,
        year: year,
      };

      await apiSurveyDcsiReportService.getRespondentTrend(params).then((response) => {
        let periods = response.data.data.periods;

        this.respondentTrend = setupRespondentTrendChart({
          data: periods,
          plugins: [ChartDataLabels],
        });

        this.setupRespondentTrendChart();
      });
    },
    exportAsPDF() {
      this.$refs.htmlToPdf.generatePdf();
    },
    async exportAsExcel() {
      let self = this;
      self.downloadLoading = true;

      await this.downloadDcsiRaw({
        q: "",
        survey_id: "",
        date: this.filters.date,
        regions: this.filters.regions.length > 0 ? this.filters.regions : "",
        zones: this.filters.zones.length > 0 ? this.filters.zones : "",
        groups: this.filters.groups.length > 0 ? this.filters.groups : "",
        dealers: this.filters.dealers.length > 0 ? this.filters.dealers : "",
      }).then(function () {
        self.downloadLoading = false;
      });
    },
    setYesterdayDate() {
      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const yesterDate = helpers.format_date(yesterday, "YYYY-MM-DD");

      this.filters.date = [yesterDate, yesterDate];
      this.loadDashbaordData(this.filters);
    },
    loadDashbaordData(params) {
      this.reappendChart = false;
      this.getScoreOverview(params);
      this.getRegionalScore(params);
      this.getFactorScore(params);
      this.getScoreTrend(params);
      this.getCdcTrend(params);
      this.getTopBottomAttributes(params);
      this.getRespondentTrend(params);
    },
  },
  computed: {
    ...mapGetters({
      dealers: "dealer/GET_DEALERS",
    }),
  },
  mounted() {
    const debug = false;

    if (debug) {
      this.MIXINS_setDummyData();
    }

    this.setYesterdayDate();
  },
};
</script>
