import api from "@/services/api/baseService";

class apiSurveyDcsiReportService {
  getScoreOverview(params) {
    return api
      .get("/survey-dcsi-report/score-overview", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getRegionalScore(params) {
    return api
      .get("/survey-dcsi-report/regional-score", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getFactorScore(params) {
    return api
      .get("/survey-dcsi-report/factor-score", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getScoreTrend(params) {
    return api
      .get("/survey-dcsi-report/score-trend", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getCdcTrend(params) {
    return api
      .get("/survey-dcsi-report/cdc-trend", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getTopBottomAttributes(params) {
    return api
      .get("/survey-dcsi-report/top-bottom-attributes", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getRespondentTrend(params) {
    return api
      .get("/survey-dcsi-report/respondent-trend", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params,
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiSurveyDcsiReportService();
